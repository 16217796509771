<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <div style="width: 70%;display: flex;">
          <el-input
            class="hunt"
            v-model="input"
            placeholder="请输入标题"
            size="large"
            @input="orangutan"
			clearable 
          />
          <!-- 类型 -->
          <el-select class="hunt"  v-model="activity" placeholder="请选择类型" @change="numerical" clearable @clear="empty" size="large">
            <el-option label="直属机关工会" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="基层工会" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select>
          <!-- 是否启用 -->
          <el-select class="hunt"  v-model="values" placeholder="请选择状态" @change="numerical1" clearable @clear="empty" size="large">
            <el-option label="已发布" value="1"  style="width:100%; text-align-last: center;" ></el-option>
            <el-option label="未发布" value="2" style="width:100%; text-align-last: center;"></el-option>
          </el-select>
          <!-- 组织名称 -->
          <el-select
            class="hunt"
            @focus="closeIt"
            v-model="ability"
            placeholder="请选择组织名称"
            size="large"
            @click="multiple"
            clearable
            @change="numerical2"
            ref="selectIt"
          />
          <el-button style="margin-left: 10px" type="primary" size="large" @click="search1" :loading='formative'
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div>
        <div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="large"
            @click="append"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div>
      </div>
      <div class="summation">
        <div class="hint">统计 : {{totality}}条</div>
        <div class="hint">提示 : 活动风采为跨分工会全员可见。</div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{ background: '#F5F7FA' }"
        :empty-text="texts"
        @row-click="unit"
      >
        <!-- 标题 -->
        <el-table-column
          label="标题"
          prop="title"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 时间 -->
        <el-table-column
          label="创建时间"
          prop="createTime"
          align="center"
          :formatter="tiems"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 所属组织 -->
        <el-table-column align="center" label="所属工会" :show-overflow-tooltip='true' prop="unionName" />
        <!-- 类型 -->
        <el-table-column align="center" label="类型" :show-overflow-tooltip='true' prop="type" :formatter="genre"/>
        <!-- 是否启用 -->
        <el-table-column align="center" label="状态" :show-overflow-tooltip='true' prop="isEnable" :formatter="formatSex"/>
        <el-table-column label="操作" align="center" width="240">
          <template #default="scope">
            <el-button
              size="default"
              @click.stop="copyreader(scope.row.id)"
              class="bulur"
              ><el-icon class="cancel"> <EditPen /> </el-icon>编辑</el-button
            >
            <el-button size="default" @click.stop="expurgate(scope.row.id)" class="red"
              ><el-icon class="cancel"> <Delete /> </el-icon>删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :current-page="currentPage"
        :page-sizes="[6, 12, 18, 24]" 
        :page-size="pagesize"
        :total="totality"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 删除按钮弹窗 -->
    <el-dialog v-model="remove" title="删除" width="30%" center>
      <div class="labor">
        <div class="sure">确定删除吗？</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="asd" size="large" type="primary" @click="affirm">确定</el-button>
          <el-button class="dsa" size="large" @click="remove=false">取消</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 所属组织弹窗 --> 
    <div>
      <s3-layer v-model="visible" title="所属工会" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div>
  </div>
</template>
<script>
import { Delete, EditPen, Search,Plus} from "@element-plus/icons-vue";
import { useRouter,useRoute  } from "vue-router";
import{ ref,onMounted,onActivated } from 'vue'
import { FClist,Zzlist,FCdelete } from '../utils/api'
import moments from 'moment'
import qs from 'qs'
import { ElMessage } from "element-plus";
export default {
  name:"elegant",
  setup() {
    const route = useRoute();
    // 搜索加载
    const formative=ref(false)
    // 判断类型
    let genre=(row, column, cellValue)=>{
      if (cellValue == 1) {
        return "直属机关工会";
      } else if (cellValue === 2) {
        return "基层工会";
      }
    }
    // 判断是否启用
    let formatSex = (row, column, cellValue) => {
      if (cellValue == 1) {
        return "已发布";
      } else if (cellValue === 2) {
        return "未发布";
      }
    };
    // 点击每一行跳转
    let unit=(row)=>{
      console.log(row)
      sessionStorage.setItem('FCHD', row.id)
      router.push('/FCdetails')
    }
    let selectIt=ref()
    // 下拉隐藏
    let closeIt=()=>{
      selectIt.value.blur();
    }
    // 删除确定
    let affirm=()=>{
      let data={
        id:modeid.value
      }
      FCdelete(qs.stringify(data)).then((res)=>{
        if(res.data.code==0){
          ElMessage({
            message: '删除成功',
            type: "success",
            center: true,
          });
          remove.value=false
          if(input.value!==''|| ability.value!==''||activity.value!==''||values.value!==''){
            search()
          }else{
            list()
          }
        }else{
          ElMessage({
            message: res.data.msg,
            type: "warning",
            center: true,
          });
          if(input.value!==''|| ability.value!==''||activity.value!==''||values.value!==''){
            search()
          }else{
            list()
          }
          remove.value=false
        }
        console.log(res)
      })
    }
    // 删除弹窗
    let remove=ref(false)
    // 获取信息id
    let modeid=ref('')
    // 删除按钮
    let expurgate=(id)=>{
      remove.value=true
      modeid.value=id
      console.log(id)
    }
    // 会员姓名输入框改变
    // const orangutan=()=>{
    //   if(input.value==''&&ability.value==''&&activity.value==''&&values.value==''){
    //     list()
    //   }
    // }
    // 选择组织名称变化
    let numerical2=()=>{
      // if(input.value==''&&ability.value==''&&activity.value==''&&values.value==''){
      //   list()
      // }
      Hcode.value=''
    }
    // let numerical1=()=>{
    //   if(input.value==''&&ability.value==''&&activity.value==''&&values.value==''){
    //     list()
    //   }
    // }
    // let numerical2=()=>{
    //   if(input.value==''&&ability.value==''&&activity.value==''&&values.value==''){
    //     list()
    //   }
    // }
    // 搜索按钮
    let search1=()=>{
      formative.value=true
      search()
    }
    let search=()=>{
      console.log(input.value)
      console.log(Hcode.value)
      console.log(values.value)
      let data={
        limit:branches.value,
        page:currentPage.value,
        isEnable:values.value,
        title:input.value,
        unionCode:Hcode.value,
        type:activity.value
      }
      FClist(qs.stringify(data)).then((res)=>{
        formative.value=false
        // 列表总数
        totality.value=res.data.data.page.totalCount
        // 列表数据
        filterTableData.value=res.data.data.page.list
        if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        console.log(res)
      })
    }
    // 类型
    let activity=ref('')
    // 是否启用
    let values=ref('')
    // 标题内容
    let input=ref('')
    // 搜索框弹窗
    // 获取code
    let Hcode=ref('')
    // 获取选项
    let ability=ref('')
    let dendrogram = (node)=>{
      ability.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    let visible=ref(false)
    let multiple=()=>{
      visible.value=true
    }
    // 搜索框
    let texture=ref([])
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
    // 获取所有组织列表
    let own=()=>{
      Zzlist().then((res)=>{
        // console.log(res)
        if(res.data.code==0){
          texture.value=res.data.data.wholeUnionList
        }
      })
    }
    let texts=ref('加载中')
      // 加载中 暂无数据列表
    let translate=ref(2)
    onActivated(()=>{
      translate.value=route.params.userId
      console.log(translate.value)
      if(translate.value==1){
        Hcode.value=''
        ability.value=''
        values.value=''
        activity.value=''
        input.value=''
        texts.value='加载中'
        // branches.value=6
        // currentPage.value=1
        list()
        own()
      }
    })
    onMounted(()=>{
      list()
      // 所有组织列表接口
      own()
    })
     // 时间转换
    let tiems=(row, colum,cellValue )=>{
    if(typeof cellValue=='number'){
      return moments(cellValue*1000).format().substring(0,10)
    }
    }
    // 分页处理
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      pagesize.value=size
      branches.value=size
      if(input.value!==''|| ability.value!==''||values.value!==''||activity.value!==''){
        search()
      }else{
        list()
      }
    }
    let handleCurrentChange=(size)=>{
      currentPage.value=size
      if(input.value!==''|| ability.value!==''||values.value!==''||activity.value!==''){
        search()
      }else{
        list()
      }
    }
    // 数据增加
    let branches=ref(6)
    // 数据总数
    let totality =ref(null)
    // 获取人物列表
    let list =()=>{
      let data={
        limit:branches.value,
        page:currentPage.value,
      }
      FClist(qs.stringify(data)).then((res)=>{
        // console.log(res)
        if(res.data.code==0){
          // 列表总数
          totality.value=res.data.data.page.totalCount
          // 列表数据
          filterTableData.value=res.data.data.page.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    // 编辑跳转
    const copyreader = (id)=>{
      console.log(id)
      sessionStorage.setItem('demeanour', id)
      router.push({ name: 'FCdit', params: { UserId: 1 }});
    }
    // 路由跳转
    const router = useRouter()
    // 头部添加跳转
    let append=()=>{
      router.push('/HDadd')
    }
    // 列表数据循环
    const filterTableData = ref([])
    return {
      // 搜索加载
      search1,
      formative,
      // 类型
      activity,
      // 判断类型
      genre,
      // 是否启用
      values,
      formatSex,
      unit,
      selectIt,
      closeIt,
      affirm,
      // 删除弹窗
      remove,
      // 删除按钮
      expurgate,
      numerical2,
      // numerical1,
      // numerical2,
      // orangutan,
      // 标题内容
      input,
      // 搜索按钮
      search,
      // 搜索框
      ability,
      dendrogram,
      visible,
      multiple,
      defaultProps,
      texture,
      // 分页处理
      handleCurrentChange,
      handleSizeChange,
      currentPage,
      pagesize,
      // 加载中 暂无数据
      texts,
      tiems,
      // 列表总数
      totality,
      // 编辑跳转
      copyreader,
      // 头部组织添加跳转
      append,
      // 循环列表数据
      filterTableData
    };
  },
  components: {
    Delete,
    EditPen,
    Search,
    Plus
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
.summation{
  display: flex;
  justify-content: space-between;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 150px);
  overflow: auto;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  margin-bottom: 15px;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 20%;
  margin-right: 10px;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}
/* .el-scrollbar__thumb {
  display: none !important;
} */
.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
